import PartnerIcon1xPng from '../images/png/icon-add-partner.png';
import PartnerIcon2xPng from '../images/png/icon-add-partner@2x.png';
import PresidentIcon1xPng from '../images/png/icon-president.png';
import PresidentIcon2xPng from '../images/png/icon-president@2x.png';
import StipendIcon1xPng from '../images/png/icon-stipend.png';
import StipendIcon2xPng from '../images/png/icon-stipend@2x.png';
import LeaderIcon1xPng from '../images/png/icon-chest.png';
import LeaderIcon2xPng from '../images/png/icon-chest@2x.png';

export interface Bonus {
  id: number;
  type: 'client_bonus' | 'lider_bonus' | 'dynamic_president_bonus' | 'dynamic_stipendial_bonus';
  image: {
    src1x: string;
    src2x: string;
  };
  title: string;
  value: number;
  info?: string;
  currency?: 'SK' | 'USDT';
  hasDynamicIncomeButton?: boolean;
}

const bonuses: Bonus[] = [
  {
    id: 0,
    type: 'client_bonus',
    image: {
      src1x: PartnerIcon1xPng,
      src2x: PartnerIcon2xPng,
    },
    title: 'Бонус за приведенного партнера',
    value: 0,
    currency: 'SK',
  },
  {
    id: 1,
    type: 'lider_bonus',
    image: {
      src1x: LeaderIcon1xPng,
      src2x: LeaderIcon2xPng,
    },
    title: 'В «Сундучке» накопилось 0 монет',
    value: 0,
    currency: 'SK',
  },
  {
    id: 2,
    type: 'dynamic_stipendial_bonus',
    image: {
      src1x: StipendIcon1xPng,
      src2x: StipendIcon2xPng,
    },
    title: 'Бонус стипендиальный',
    value: 0,
    currency: 'USDT',
    hasDynamicIncomeButton: true,
  },
  {
    id: 3,
    type: 'dynamic_president_bonus',
    image: {
      src1x: PresidentIcon1xPng,
      src2x: PresidentIcon2xPng,
    },
    title: 'Бонус президентский',
    value: 0,
    currency: 'USDT',
  },
];

export default bonuses;