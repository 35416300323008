import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import client from '../api/apiSkillPro';

export interface User {
  user_id: number;
  clicks: number;
  client_bonus: number;
  email: string;
  first_name: string;
  from_ref_link: string;
  id: number;
  is_active: true;
  last_name: string;
  last_time_visit: string;
  level: number;
  lider_bonus: number;
  personalID: string;
  phone: string;
  president_bonus: number;
  dynamic_stipendial_bonus: number;
  dynamic_president_bonus: number;
  ref_code: string;
  skillcoin_balance: number;
  stipendial_bonus: number;
  telegram: string;
  usdt_balance: number;
  date_joined: string;
}

const useUserQuery = () => {
  return useQuery<User, AxiosError>({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await client.get<User>('/api/user');
      return data;
    },
    staleTime: 5 * 60 * 1000,
  });
};

export default useUserQuery;
