enum RouteNames {
  HOME = '/',
  QUESTS = 'quests',
  QUESTS_TRANSACTIONS = 'transactions',
  PARTNERS = 'partners',
  CHATS = 'chats',
  TRANSACTIONS = 'transactions',
  SUPPORT = 'support',
  TICKETS = 'tickets',
  TICKET = 'support/tickets/:id',
  SETTINGS = 'settings',
  REGISTRATION = 'registration',
  LOGIN = 'login',
  FORGOTPASSWORD = 'forgot-password',
  START = 'start',
  ANY = '*',
}

export default RouteNames;
