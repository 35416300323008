import { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import Spinner from './components/Spinner';
import Error from './components/Error';
import Router from './router';
import styles from './App.module.css';

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return <Error onClick={resetErrorBoundary} errorType="other" />;
};

function App() {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          <Suspense fallback={<Spinner className={styles.app__spinner} />}>
            <Router isAuth={isAuth}/>
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

export default App;
