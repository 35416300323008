import { useState, useEffect } from "react";
import axios from "axios";

interface MonthlySkillcoinData {
  [key: string]: number;
}

const API_URL = process.env.REACT_APP_API_URL;

const useMonthlySkillcoin = (userId: number) => {
  const [data, setData] = useState<MonthlySkillcoinData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const getMonthlySkillcoinData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/monthly_skillcoin/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setData(response.data.data || {});
      } catch {
        setError("Ошибка получения данных");
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      getMonthlySkillcoinData();
    }
  }, [userId]);

  return { data, loading, error };
};

export default useMonthlySkillcoin;
