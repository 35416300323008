import { useState, useEffect } from "react";
import axios from "axios";

interface MonthlyBonusData {
  [key: string]: number;
}

const API_URL = process.env.REACT_APP_API_URL;

const useMonthlyBonus = () => {
  const [data, setData] = useState<MonthlyBonusData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const getMonthlyBonusData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/monthly_bonus/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setData(response.data.data || {});
      } catch {
        setError("Ошибка получения данных");
      } finally {
        setLoading(false);
      }
    };

    getMonthlyBonusData();
  }, []);

  return { data, loading, error };
};

export default useMonthlyBonus;