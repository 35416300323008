import { ReactComponent as Main } from '../images/icons/home.svg';
import { ReactComponent as Quest } from '../images/icons/quest.svg';
import { ReactComponent as Partners } from '../images/icons/partners.svg';
import { ReactComponent as Wallet } from '../images/icons/wallet.svg';
import { ReactComponent as Chats } from '../images/icons/chats.svg';
import { ReactComponent as Help } from '../images/icons/help.svg';
import { ReactComponent as City } from '../images/icons/city.svg';

export interface MenuItem {
  id: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  url: string;
}

const menuItems: MenuItem[] = [
  {
    id: 0,
    icon: Main,
    text: 'Главная',
    url: '/',
  },
  {
    id: 1,
    icon: Quest,
    text: 'Игровые квесты',
    url: '/quests',
  },
  {
    id: 2,
    icon: Partners,
    text: 'Партнерский раздел',
    url: '/partners',
  },
  {
    id: 3,
    icon: Wallet,
    text: 'Балансовые операции',
    url: '/transactions',
  },
  {
    id: 4,
    icon: Chats,
    text: 'Квест-чаты',
    url: '/chats',
  },
  {
    id: 5,
    icon: Help,
    text: 'Поддержка',
    url: '/support',
  },
  {
    id: 6,
    icon: City,
    text: 'Выход в город',
    url: '/start',
  },
];

export default menuItems;
