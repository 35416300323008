/* eslint-disable import/prefer-default-export */
import type { Avatar } from '../hooks/useAvatarQuery';
import type { User } from '../hooks/useUserQuery';
import type { PartnersConversion } from '../hooks/usePartnersConversionQuery';
import bonuses from '../configs/bonuses';
import partnerBonuses from '../configs/partnerBonuses';
import DefaultAvatar from '../images/png/default-avatar.png';

export const getAvatar = (avatarArr: Avatar[]): Avatar => {
  const avatar = avatarArr[avatarArr.length - 1];
  return (
    avatar || {
      id: 0,
      user_id: 0,
      avatar: DefaultAvatar,
    }
  );
};

export const getUserBonuses = (user: User, lidersFundCount: number, level: number) => {
  return bonuses.map((bonus) => {
    let value = 0;

    if (bonus.type === 'lider_bonus') {
      value = level >= 2 ? lidersFundCount : 0;
    } else {
      value = user[bonus.type];
    }

    return {
      ...bonus,
      value,
      title: bonus.type === 'lider_bonus'
        ? `В «Сундучке» накопилось ${lidersFundCount} монет`
        : bonus.title,
    };
  });
};

export const getPartnersBonuses = (partnersConversion: PartnersConversion) => {
  return partnerBonuses.map((bonus) => ({
    ...bonus,
    value: partnersConversion[bonus.type],
  }));
};
