import Card from '../Card';
import Image from '../Image';
import Info from '../Info';
import { formatValue } from '../../utils/number';
import styles from './BonusCard.module.css';

interface Props {
  image: {
    src1x: string;
    src2x: string;
  };
  title: string;
  value: number;
  info?: string;
  currency?: 'SK' | 'USDT';
  className?: string;
  children?: React.ReactNode;
}

const BonusCard = ({
  image: { src1x, src2x },
  title,
  value,
  currency,
  info,
  className,
  children,
}: Props) => {
  return (
    <Card className={className}>
      <div className={styles['bonus-card']}>
        <div className={styles['bonus-card__header']}> 
          <div className={styles['bonus-card__icon']}>
            <Image src1x={src1x} src2x={src2x} alt={title} />
          </div>
          <div className={styles['bonus-card__extra']}>
            {children}
          </div>
        </div>
        <div className={styles['bonus-card__content']}>
          <div className={styles['bonus-card__title']}>
            <span>{title}</span>
            {info && <Info text={info} />}
          </div>
          <div className={styles['bonus-card__value-wrapper']}>
            <span className={styles['bonus-card__value']}>
              {formatValue(value)}
            </span>
            {currency && (
              <span className={styles['bonus-card__currency']}>{currency}</span>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BonusCard;
