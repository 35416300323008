import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useMedia from 'use-media';
import Card from '../Card';
import styles from './Profile.module.css';
import ButtonIcon from '../ButtonIcon';
import Avatar from '../Avatar';
import Wallet from '../Wallet';
import ReferalLink from '../ReferalLink';
import BonusCard from '../BonusCard';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/log-out.svg';
import useUserQuery from '../../hooks/useUserQuery';
import useAvatarQuery from '../../hooks/useAvatarQuery';
import { getAvatar, getUserBonuses } from '../../utils/data';
import useLidersFundCount from '../../hooks/useLidersFundCount';
import useMonthlyBonus from '../../hooks/useMonthlyBonus';
import useMonthlySkillcoin from '../../hooks/useMonthlySkillcoin';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

interface Props {
  onClose?: () => void;
  onSettingsClick?: () => void;
  onLogOutClick?: () => void;
  className?: string;
}

const Profile = ({
  onSettingsClick,
  onLogOutClick,
  onClose,
  className,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBonusType, setSelectedBonusType] = useState<string | null>(null);
  const [chartData, setChartData] = useState<any>(null);
  const navigate = useNavigate();

  const { data: user } = useUserQuery(); 
  const { data: avatarArr } = useAvatarQuery();
  const { count: lidersFundCount, error } = useLidersFundCount();
  const { data: monthlyBonusData, loading, error: bonusError } = useMonthlyBonus();
  const { data: monthlySkillcoinData } = useMonthlySkillcoin(user ? user.id : 0);

  const isTablet = useMedia('(min-width: 768px)');
  const showSettingButtons = !isTablet;

  useEffect(() => {
    if (isModalOpen && user && selectedBonusType) {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];

      const userJoinDate = new Date(user.date_joined);
      const userJoinMonth = userJoinDate.getMonth();
      const currentMonth = new Date().getMonth();

      setChartData(null);

      if (selectedBonusType === 'monthlyBonus' && monthlyBonusData) {
        const monthlyIncome = months.map(month => monthlyBonusData[month] || 0);
        const validMonthlyIncome = monthlyIncome.slice(userJoinMonth, currentMonth);
        const validMonths = months.slice(userJoinMonth, userJoinMonth + validMonthlyIncome.length);

        if (validMonthlyIncome.length > 0) {
          setChartData({
            labels: validMonths,
            datasets: [
              {
                label: 'USDT',
                data: validMonthlyIncome,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        }
      }

      if (selectedBonusType === 'skillcoin' && monthlySkillcoinData) {
        const monthlySkillcoin = months.map(month => monthlySkillcoinData[month] || 0);
        const validMonthlySkillcoin = monthlySkillcoin.slice(userJoinMonth, currentMonth);
        const validMonths = months.slice(userJoinMonth, userJoinMonth + validMonthlySkillcoin.length);

        if (validMonthlySkillcoin.length > 0) {
          setChartData({
            labels: validMonths,
            datasets: [
              {
                label: 'Skillcoin',
                data: validMonthlySkillcoin,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        }
      }
    }
  }, [isModalOpen, selectedBonusType, monthlyBonusData, monthlySkillcoinData, user]);

  const handleClick = (bonusType: string) => {
    setSelectedBonusType(bonusType);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBonusType(null);
  };

  if (!user || !avatarArr) return null;
  if (error || bonusError) return <div>{error || bonusError}</div>;

  const avatar = getAvatar(avatarArr);
  const bonuses = getUserBonuses(user, lidersFundCount || 0, user.level);
  const refLink = `${process.env.REACT_APP_WEB_URL}/registration?ref=${user.ref_code}`;

  const handleWalletClick = () => {
    if (onClose) onClose();
    navigate('/transactions');
  };

  return (
    <div className={classNames(styles.profile, className)}>
      <Card className={styles.profile__card}>
        <div className={styles.profile__info}>
          <div className={styles.profile__header}>
            {showSettingButtons && (
              <ButtonIcon
                appearance="secondary"
                size="medium"
                onClick={onSettingsClick}
              >
                <SettingsIcon />
              </ButtonIcon>
            )}
            <Avatar size="large" withBorder imageUrl={avatar.avatar} />
            {showSettingButtons && (
              <ButtonIcon
                appearance="secondary"
                size="medium"
                onClick={onLogOutClick}
              >
                <LogOutIcon />
              </ButtonIcon>
            )}
          </div>
          <div className={styles.profile__content}>
            <p className={styles.profile__title}>
              {`${user.first_name} ${user.last_name}`}
            </p>
            <Wallet
              usdtValue={user.usdt_balance}
              skillCoinValue={user.skillcoin_balance}
              className={styles.profile__wallet}
              onClick={handleWalletClick}
            />
            <ReferalLink linkUrl={refLink} />
          </div>
        </div>
      </Card>
      <div className={styles['profile__bonus-cards-wrapper']}>
        {bonuses.map(({ id, image, title, value, currency, type, hasDynamicIncomeButton }) => (
          <div key={id} className={styles['profile__bonus-card']}>
            <BonusCard
              image={image}
              title={title}
              value={value}
              currency={currency}
            >
              {type === 'dynamic_stipendial_bonus' && hasDynamicIncomeButton && (
                <div className={styles['bonus-card__extra']}>
                  <button
                    type="button"
                    className={classNames(styles.dynamicIncomeBtn, {
                      [styles.disabled]: user.level < 2,
                    })}
                    onClick={user.level >= 2 ? () => handleClick('monthlyBonus') : undefined} 
                    disabled={user.level < 2}
                  >
                    Личный доход в динамике
                  </button>
                </div>
              )}
              {type === 'client_bonus' && (
                <div className={styles['bonus-card__extra']}>
                  <button
                    type="button"
                    className={styles.dynamicIncomeBtn}
                    onClick={() => handleClick('skillcoin')}
                  >
                    Посмотреть в динамике
                  </button>
                </div>
              )}
            </BonusCard>
          </div>
        ))}
      </div>

      {/* Модальное окно с графиком */}
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modal__content}>
            <button type="button" onClick={handleCloseModal} className={styles.modal__close}>
              ✖
            </button>
            {chartData ? (
              <div className={styles.graph}>
                <Line data={chartData} />
              </div>
            ) : (
              <p className={styles.noDataMessage}>Данные будут отображаться со следующего месяца.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
