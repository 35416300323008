import { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const useLidersFundCount = () => {
  const [count, setCount] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    const getLidersFundData = async () => {
      try {
        const [countRes, userRes] = await Promise.all([
          axios.get(`${API_URL}/api/lidersfund/count/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const userLevel = userRes.data.level;
        setLevel(userLevel);

        setCount(userLevel >= 2 ? countRes.data.count : 0);
      } catch {
        setError('Ошибка получения данных');
      }
    };

    getLidersFundData();
  }, []);

  return { count, level, error };
};

export default useLidersFundCount;