import classNames from 'classnames';
import { ReactComponent as LogoBig } from '../../images/svg/logo.svg';
import { ReactComponent as LogoSmall } from '../../images/svg/logo-compact.svg';
import styles from './Logo.module.css';

interface Props {
  size?: 'big' | 'small';
  className?: string;
}
const Logo = ({ size = 'big', className }: Props) => {
  return (
    <div className={classNames(styles.logo, className)}>
      {size === 'big' && <p className={styles.title}>Цифровой Университет</p>}
      {size === 'big' ? <LogoBig /> : <LogoSmall />}
    </div>
  );
};
export default Logo;
